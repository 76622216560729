import Rails from '@rails/ujs'
import "@hotwired/turbo-rails"
import 'arrive'
import 'bootstrap'


// main menu
document.arrive('.menu-toggler', { existing: true }, function(el) {
  el.addEventListener('click', function(event){
    event.preventDefault()
    document.querySelectorAll('.menu-toggler').forEach(function(el){ el.classList.toggle('d-none') })
    document.querySelector('nav.menu').classList.toggle('open')
  })
})
document.arrive('nav.menu a', { existing: true }, function(el) {
  el.addEventListener('click', function(event) {
    document.querySelectorAll('.menu-toggler').forEach(function (el) { el.classList.toggle('d-none') })
    document.querySelector('nav.menu').classList.toggle('open')
  })
})


// scroll to anchor on load when needed
document.addEventListener('turbo:load', function() {
  if (window.location.hash) {
    var el = document.querySelector(window.location.hash)
    if (el) { el.scrollIntoView(true) }
  }
})


// menu links - handle scroll when on the same page
document.arrive('nav.menu a', { existing: true }, function(el) {
  if (el.pathname === window.location.pathname) {
    el.addEventListener('click', function(event) {
      event.preventDefault()
      document.querySelector(el.hash).scrollIntoView(true)
    })
  }
})


// homepage scrolling arrows
document.arrive('body.home .link a', { existing: true }, function(el) {
  let target = el.getAttribute('href')
  el.addEventListener('click', function(event) {
    event.preventDefault()
    document.querySelector(target).scrollIntoView(true)
  })
})


// case study collapse helper
document.arrive('.case-study .collapse', { existing: true }, function (el) {
  el.addEventListener('show.bs.collapse', function (event) { el.closest('.case-study').classList.add('active') })
  el.addEventListener('hide.bs.collapse', function (event) { el.closest('.case-study').classList.remove('active') })
})


// header & footer scroll-based updates
document.addEventListener('turbo:load', function() {
  // header
  const headerTracker = document.querySelector('.scroll-tracker')
  const header = document.querySelector('header')
  const headerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) { header.classList.remove('scrolled') }
      else { header.classList.add('scrolled') }
    })
  }
  new IntersectionObserver(headerCallback).observe(headerTracker)
  // footer
  const footerTracker = document.querySelector('.footer-tracker')
  const footer = document.querySelector('footer')
  const footerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) { footer.classList.add('fadeIn') }
      else { footer.classList.remove('fadeIn') }
    })
  }
  new IntersectionObserver(footerCallback).observe(footerTracker)
})


// shareon
import * as Shareon from 'shareon'
import 'shareon/css'
document.addEventListener('turbo:load', function (event) { Shareon.init() })


// Swiper
import Swiper, { HashNavigation } from 'swiper'
import 'swiper/css'
document.arrive('.swiper', { existing: true }, function(el) {
  const swiper = new Swiper(el, {
    speed: 700,
    modules: [HashNavigation],
    hashNavigation: { watchState: true },
    on: { slideChangeTransitionEnd: function() { el.closest('section').scrollIntoView() } }
  })
})


// Google map
document.arrive('.office-map', { existing: true }, function(el) {
  var coords = new google.maps.LatLng(el.dataset.latitude, el.dataset.longitude)
  var opts = {
    auto_adjust: true,
    auto_zoom: false,
    center: coords,
    zoom: 16
  }
  var map = new google.maps.Map(el, opts)
  var marker = new google.maps.Marker({ position: coords, map: map, title: 'Solutions HR Specialists' })
  var info = new google.maps.InfoWindow({ content: `<strong>${el.dataset.name}</strong><br>${el.dataset.address}` })
  google.maps.event.addListener(marker, 'click', function () {
    info.open(map, marker)
  })
})


// GA + Turbo
document.addEventListener('turbo:load', function (event) {
  if (typeof (ga) === 'function') {
    ga('set', 'location', event.detail.url)
    ga('send', 'pageview')
  }
});


// plausible.io + Turbo
document.addEventListener('turbo:load', function() {
  // if (! typeof plausible === 'undefined') { plausible('pageview') }
  try { plausible('pageview') }
  catch(err) { console.log('Plausible: not available.') }
})


// make all external links open in new tab
document.arrive('.richtext', { existing: true }, function(el) {
  const selector = "a[href^='http']:not([href^='http://www.solutions-hrs.com']):not([href^='http://solutions-hrs.com']):not([href^='https://www.solutions-hrs.com']):not([href^='https://solutions-hrs.com'])"
  el.querySelectorAll(selector).forEach(function (e) {e.setAttribute("target", "_blank") })
})


// homepage animations
import '~/javascript/homepage_animations'


// buttons
import '~/javascript/buttons'


// CSS Styles
import '~/stylesheets/website.scss'
